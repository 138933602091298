.lighting-list-container {
  background-color: #141b1f;
  color: white;
  padding: 3% 0% 3% 0%;
}

.lighting-list {
  /* padding: 3% 0% 10% 0%; */
  font-size: 20px;
  /* display: flex; */
  border: 2px solid #141b1f;
  padding-bottom: 10%;
  padding-left: 5%;
}

.lighting-list:hover {
  color: #a58d68;
}

.lighting-solutions {
  padding-bottom: 3%;
  font-weight: bold;
}

#bolt-icon {
  margin-right: 1%;
}

.circle-plug-icon {
  margin-right: 3%;
}

.lighting-list-col {
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .lighting-list {
    font-size: 14px;
  }
}

@media only screen and (max-width: 770px) {
  .lighting-list {
    font-size: 17px;
  }
}

@media only screen and (max-width: 450px) {
  .lighting-list {
    font-size: large;
  }
  .lighting-list {
    /* padding: 3% 0% 10% 0%; */
    /* font-size: 20px; */
    /* display: flex; */
    /* border: 2px solid #141b1f; */
    padding-bottom: 0%;
    padding-left: 0%;
  }
}

@media only screen and (max-width: 320px) {
  .lighting-list {
    font-size: large;
  }
}
