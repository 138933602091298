.footer-container {
  background-color: #141b1f;
  color: white;
  padding: 1% 0% 1% 0%;
  /* text-align: left; */
  /* width: 100%; */
}

#footer-name,
#footer-description,
#footer-label,
#footer-number,
#footer-email {
  text-align: left;
}

#footer-label,
#footer-number,
#footer-email {
  padding-left: 30%;
}

.footer-social-icons {
  font-size: 30px;
  margin-right: 5%;
}

#footer-number {
  margin-bottom: 0rem;
}

.footer-social-icon-a {
  color: white;
}

.footer-social-icon-a:hover {
  color: #a58d68;
}

@media screen and (max-width: 450px) {
  #footer-email {
    padding-left: 0%;
  }
  #footer-label,
  #footer-number,
  #footer-email {
    padding-left: 0%;
  }

  #footer-name {
    margin-top: 3%;
  }
  #footer-social-icons-container {
    margin-bottom: 3%;
  }
}
