.navbar-brand {
  color: white;
}

.nav-continer {
  max-width: 90%;
}

.main-nav {
  position: absolute;
  background-color: rgba(20, 27, 31, 0.7);
  border-bottom: 5px solid rgba(104, 119, 79, 0.7);
  width: 100%;
  z-index: 500;
}

.ma-logo {
  position: absolute;
  width: 13%;
  top: 0;
}

.hp-link {
  color: white;
  font-size: 25px;
}

.social-icons {
  color: white;
  font-size: 32px;
}

.social-icons:hover {
  color: #a58d68;
}

.social-icon-container {
  margin-left: 20%;
  margin-top: 7%;
}

#call-today {
  font-size: 30px;
}

#call-today:hover {
  color: white;
}
.navbar-nav .nav-link.active {
  color: white;
}

.navbar-nav .nav-link:hover {
  color: #a58d68;
}

.home-link {
  width: 15%;
}

.ms-auto {
  margin-left: auto;
}

a.social-icon-container.nav-link {
  padding: 0% !important;
}

@media only screen and (max-width: 1440px) {
  #call-today {
    font-size: 21px;
  }
  .nav-continer {
    /* width: 87%; */
  }
  .hp-link {
    font-size: 24px;
  }
  .social-icon-container {
    flex-basis: none;
  }

  .nav-continer {
    max-width: 86%;
  }
}

@media only screen and (max-width: 810px) {
  .main-nav {
    /* position: relative; */
    background-color: rgba(20, 27, 31);
  }
  .ma-logo {
    position: absolute;
    width: 20%;
    top: 0;
  }

  .social-icon-container1 {
    display: block;
    margin-top: 7%;
    margin-bottom: 5%;
  }

  #call-today {
    font-size: 26px;
    /* width: auto; */
    /* display: flex; */
    margin-right: 0px;
  }
}

.navbar-toggler {
  background-color: #68774f;
}

@media only screen and (max-width: 450px) {
  .ma-logo {
    position: absolute;
    width: 28%;
    top: 0;
  }
}
