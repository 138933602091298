.main-header-line {
  margin-top: 2%;
  margin-bottom: 2%;
}

.services-container {
  margin-top: 2%;
  margin-bottom: 2%;
}

.main-content-h1 {
  font-weight: bolder;
  font-size: 50px;
}

.card-body-title,
.card-body-text {
  margin-top: 3%;
}

#mission-statement {
  font-size: large;
}

.services-card-style {
  width: auto;
  height: 100%;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .main-content-h1 {
    font-weight: bolder;
    font-size: 27px;
  }
}

@media only screen and (max-width: 810px) {
}
.card-body-text.card-text {
  font-size: small;
  text-align: left;
}

@media only screen and (max-width: 450px) {
  .main-content-h1 {
    font-size: 20px;
    text-align: left;
  }

  .services-card-style {
    width: auto;
    height: auto;
    margin-bottom: 8%;
  }
  #mission-statement {
    font-size: small;
    text-align: left;
  }
  .card-body-text {
    font-size: small;
  }
}

@media only screen and (max-width: 320px) {
  .main-content-h1 {
    font-size: 17px;
  }
  #mission-statement {
    font-size: small;
  }
}
