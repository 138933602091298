.review-section {
  background-color: #dbdbdb;
  padding-top: 2%;
  padding-bottom: 2%;
}

#card-row-1 {
  margin-bottom: 3%;
}

.review-card-header {
  display: flex !important;
}

.review-card-title {
  display: flex;
}

.review-card-text {
  text-align: left;
}

.checked {
  color: orange;
}

#reviews-title {
  padding-bottom: 2%;
}

#write-review-button {
  background-color: #68774f;
  border: none;
}

#write-review-button:hover {
  background-color: #a58d68;
}

#write-review-container {
  margin-top: 3%;
}

@media screen and (max-width: 450px) {
  #review-card1,
  #review-card3 {
    margin-bottom: 3%;
  }
  .review-card-text {
    font-size: small;
  }
}
