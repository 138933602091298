.banner-container {
  width: 100%;
  height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-image {
  position: relative;
  width: -webkit-fill-available;
  height: auto;
  padding-top: 9%;
}

.text-overlay {
  position: absolute;
  font-size: 72px;
  font-weight: bold;
  color: white;
  border-left-style: solid;
  border-bottom-style: solid;
  width: 52%;
}

.banner-overlay {
  position: absolute;
  width: 100%;
  height: 79vh;
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 1440px) {
  .text-overlay {
    font-size: 56px;
    margin-top: 8%;
  }
}

@media only screen and (max-width: 1081px) {
  .text-overlay {
    font-size: 40px;
    margin-top: 0%;
  }

  .banner-overlay {
    height: 55vh;
  }

  .banner-container {
    height: 55vh;
  }
}

@media only screen and (max-width: 810px) {
  .text-overlay {
    font-size: 54px;
    margin-top: 10%;
    width: 87%;
  }
}

@media only screen and (max-width: 768px) {
  .banner-overlay {
    height: 45vh;
  }
  .banner-container {
    height: 45vh;
  }
  .text-overlay {
    font-size: 43px;
    margin-top: 10%;
    width: 73%;
  }
}

@media only screen and (max-width: 450px) {
  .banner-container {
    height: 35vh;
    align-items: normal;
  }
  .banner-overlay {
    height: 35vh;
  }
  .text-overlay {
    border: none;
    margin-top: 25%;
    filter: drop-shadow(5px 5px 5px #000);
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .banner-container {
    height: 35vh;
    align-items: normal;
  }
  .banner-overlay {
    height: 35vh;
  }
  .text-overlay {
    font-size: 55px;
  }
}

@media only screen and (max-width: 320px) {
  .text-overlay {
    font-size: 46px;
  }
}
